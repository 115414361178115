.values {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 3%;
}

.text {
  color: #f46100;
  display: flex;
  flex-direction: column;
  text-align: justify;
  max-width: 700px;
  margin: 0 auto;
  justify-content: center;
  font-size: 20px;
}

.value-row {
  display: flex;
  justify-content: space-evenly;
}

.value-row img {
  width: 40%;
  margin-bottom: 5%;
}

.value-column {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.values-title {
  font-size: 25px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .values {
    flex-direction: column;
  }

  .value-row {
    flex-direction: column;
    align-items: center;
  }

  .value-row img {
    width: 60%;
  }

}
