.banner-container {
  position: relative;
  background-image: url('../../resources/banner-background.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
}

.banner-container img {
  width: 100%;
  height: auto;
  margin-bottom: 3%;
}

.banner-container .banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5% 5% 8%;
}

.banner-container .banner div {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 700px;
  margin: 0 auto;
  justify-content: center;
  font-size: 20px;
}

.banner-container a {
  display: flex;
  text-decoration: none;
}

.banner-container .btn {
  background-color: #f46100;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5%;
  max-width: 60%;
}

.banner-container .btn:hover {
	background:#d8642a;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .banner-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .banner {
    align-items: center;
  }

  .banner-container img {
    float: right;
    width: 85%;
  }

  .banner-container .banner {
    margin: 0 5% 8% 8%;
  }

  .banner-container .btn {
    max-width: 100%;
    margin-bottom: 15%;
  }

  .banner-container .banner div {
    text-align: center;
  }
}
