.services-title {
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.services {
  display: flex;
  margin: 50px 0;
  justify-content: space-evenly;
}

.services-container {
  padding-top: 10%;
  background-image: url('../../resources/services-background.png');
  background-size: 100% 100%;
}

.service1 {
  position: relative;
  border-radius: 5px;
  margin-bottom: 5%;
  width: 300px;
  height: 400px;
  background-image: url('../../resources/service1.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.service1:hover {
  background-image: url('../../resources/service1-hover.png');
}

.service2 {
  position: relative;
  border-radius: 5px;
  margin-bottom: 5%;
  width: 300px;
  height: 400px;
  background-image: url('../../resources/service2.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.service2:hover {
  background-image: url('../../resources/service2-hover.png');
}

.service3 {
  position: relative;
  border-radius: 5px;
  margin-bottom: 5%;
  width: 300px;
  height: 400px;
  background-image: url('../../resources/service3.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.service3:hover {
  background-image: url('../../resources/service3-hover.png');
}


.service1:hover > .service-text {
 color: #000;
}

.service2:hover > .service-text {
  color: #000;
}

.service3:hover > .service-text {
  color: #000;
 }

.service img {
  border-radius: 5px;
  position: relative;
  width: 300px;
}

.service-text {
  top: 50%;
  left: 50%;
  margin: 0;
  color: white;
  position: absolute;
  width: 90%;
  transform: translate(-50%, -50%);
}

.service-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.service-subtitle {
  font-size: 0.8em;
}

@media only screen and (max-width: 1024px) {
  .services {
    flex-direction: column;
    align-items: center;
  }

  .services-title {
    margin-top: 100px;
  }
}

