.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.language {
  border: 1px solid #bfbfbf;
  margin-left: 10px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #bfbfbf;
  line-height: 15px;
  min-width: 30px;
  text-align: center;
  cursor: pointer;
  margin-right: 1rem;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  z-index: 1;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  display: block;
  width: 100%;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: 500;
}

.navigation-menu:hover li:hover a:hover {
  text-decoration: none;
  border-bottom: 1px solid #000;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: transparent;
}

@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
    margin-right: 2rem;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #f46100;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .brand-name {
    display: flex;
    margin-left: 2rem;
  }
}
