body {
  font-family: 'Exo', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
}

@font-face {
  font-family: "Exo";
  src: local("Exo"), url("../../assets/fonts/Exo-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Exo";
  src: local("Exo"), url("../../assets/fonts/Exo-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Exo";
  src: local("Exo"), url("../../assets/fonts/Exo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Exo";
  src: local("Exo"), url("../../assets/fonts/Exo-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

.bg-img {
  min-height: 380px;
  max-width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}

.App {
  text-align: center;
}
