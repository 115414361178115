.footer {
  background-color: #b9eff4;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding-top: 3%;
  padding-bottom: 2%;
}

.footer img {
  margin-left: 5%;
  align-self: center;
}

.footer-rows {
 display: flex;
 flex-direction: column;
 align-items: flex-end;
 margin-right: 5%;
 width: 100%;
}

.footer-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
 }

 .footer-row h4 {
  margin-top: 1%;
  margin-bottom: 1%;
  font-weight: 400;
 }

 .footer-row h4 a {
  text-decoration: none;
  color: black;
 }


 @media only screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
  }

  .footer img {
    margin-left: 0;
    width: 50%;
    height: 50%;
  }

  .footer-rows {
    justify-items: flex-end;
  }

  .footer-row {
    justify-content: center;
    margin-bottom: 1%;
  }
}
