.contact-container {
  display: flex;
  justify-content: space-between;
  padding: 5rem 0;
  color: #f9f9f9;
  letter-spacing: 1px;
}

.contact-col {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: black;
  justify-content: center;
}

.block-container {
  position: relative;
  max-width: 700px;
}

.text-wrapper {
  border-radius: 3rem;
  max-width: 400px;
  color: black;
  font-size: 24px;
}

.content {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}

.contact-form {
  width: 100%;
  min-width: 300px;
}

.field {
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
}

.field-label {
  display: flex;
  margin-left: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.contact-input {
  width: 100%;
  font-size: 0.875rem;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 10px;
  background-color: #e1e1e1;
}

.contact-input::placeholder {
  font-family: 'Montserrat', sans-serif;
}

textarea {
  resize: none;
}

.contact-title {
  text-align: left;
  color: #f4670a;
  font-size: 30px;
}

.separator {
  position: relative;
  width: 30px;
  height: 1px;
  top: -15px;
  background-color: #f4670a;
}

button[type="submit"] {
	cursor:pointer;
	width: 40%;
	border:none;
  border-radius: 5px;
	background:#f46100;
	color:#FFF;
	padding:10px;
	font-size:15px;
}

.field-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 5px;
}

button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

button[type="submit"]:hover {
	background:#d8642a;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .contact-container {
    padding: 3rem 0;
    margin-left: 0rem;
    flex-direction: column;
  }

  .contact-form {
    max-width: 100%;
    margin-top: 2rem;
  }

  .separator {
    display: none;
  }
}
